import React, { useState, useEffect } from 'react';

const ActivityTracker = ({ userId }) => {
  const [isActive, setIsActive] = useState(true);
  const [timer, setTimer] = useState(null);

  // Log activity status to the console for testing
  const logActivityStatus = (status) => {
    //console.log(`User ${userId} is ${status ? 'active' : 'inactive'}`);

    // we can integrate api here to store activity of user in DB
  };

  
  const resetTimer = () => {
    if (timer) {
      clearTimeout(timer);  
    }

    const newTimer = setTimeout(() => {
      setIsActive(false);
      logActivityStatus(false);  
    }, 300000); 

    setTimer(newTimer);
  };

  useEffect(() => {
    
    const handleActivity = () => {
      setIsActive(true);
      logActivityStatus(true); 
      resetTimer();
    };
    
    // window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('focus', handleActivity);
    window.addEventListener('blur', handleActivity);
   
    resetTimer();
    
    return () => {
    //   window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('focus', handleActivity);
      window.removeEventListener('blur', handleActivity);
      clearTimeout(timer);
    };
  }, [timer]);

  return null;
};

export default ActivityTracker;