import { useState, useContext } from "react";
import "./friendsListing.scss";
import FriendItem from "./FriendItem";
import { AuthContext } from "../../context/authContext";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import LoadingFriends from "./LoadingFriends";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const FriendsListing = ({ userId }) => {
  const { currentUser } = useContext(AuthContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const {t} = useTranslation();  

  const id = userId || currentUser.id;

  // Function to fetch the friends list
  const fetchFriends = async (id) => {
    const response = await makeRequest.get(`/ssakti/users/friendrequest/getFriendsList/${id}`);   
    queryClient.setQueryData(['friends', id], { ...response.data, totalFriend: response.data.totalFriend }); 
    // const totalFriends = response.data.friendListData
    // const totalFriend = queryClient.getQueryData(['friends', id]);
    // const count = totalFriend.filter(friend => friend.status === "APPROVED")
    // totalFriendRef.current = count.length;    
    return response.data.friendListData;
  };

  // Function to fetch friend requests
  const fetchRequests = async (id) => {
    if (id === currentUser.id) {
      const response = await makeRequest.get(`/ssakti/users/friendrequest/getFriendRequest/${id}`);
      return response.data.friendRequestData;
    }
    return []; // Return empty array if not the current user
  };

  // Query to get friends data
  const { data: friends = [], isLoading: friendsLoading, isError: friendsError } = useQuery(
    ['friends', id],
    () => fetchFriends(id),{
      refetchOnWindowFocus:false,
      staleTime: 600000, 
      cacheTime: 600000,
    }
  );

  const totalFriend = queryClient.getQueryData(['friends', id]);  
  // Query to get friend requests, only if the id is the current user
  const { data: requests = [], isLoading: requestsLoading, isError: requestsError } = useQuery(
    ['requests', id],
    () => fetchRequests(id),
    { enabled: id === currentUser.id ,
      refetchOnWindowFocus:false,
      staleTime: 600000, 
      cacheTime: 600000,
    } // Only run this query if id matches current user
  );

  // Mutation to approve a friend request
  const approveMutation = useMutation(
    async (reqId) => {
      await makeRequest.put(`/ssakti/users/friendrequest/approveFriendRequest/${reqId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['requests']);
        queryClient.invalidateQueries(['friends']);
      },
      onError: (error) => console.error("Error approving friend request:", error),
    }
  );

  // Mutation to reject a friend request
  const rejectMutation = useMutation(
    async (reqId) => {
      await makeRequest.put(`/ssakti/users/friendrequest/rejectFriendRequest/${reqId}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['requests']);
        queryClient.invalidateQueries(['friends']);
      },
      onError: (error) => console.error("Error rejecting friend request:", error),
    }
  );

  // Function to handle closing of the modal
  const handleClose = () => setIsModalVisible(false);

  return (
    <div className="friendsListings">
      <div className="friendsHeadingContainer">
        <div>
        <div className="heading">{t("friends")}</div>
        <h5>Total Friend:{totalFriend?.filter(friend => friend.status === "APPROVED").length}</h5>
        </div>        

        {id === currentUser.id && (
          <div className="requests">            
            <button onClick={() => setIsModalVisible(true)}>
              {t("requests")}: <span>{requests.length || 0}</span>
            </button>
          </div>
        )}
      </div>
      {friendsLoading ? (
        <LoadingFriends />
      ) : friendsError ? (
        "Error fetching friends."
      ) : friends.length === 0 ? (
        "No friends found"
      ) : (
        friends.filter(friend => friend.status === "APPROVED").map(friend => (
          <FriendItem key={friend.id} friend={friend} />
        ))
      )}

      {isModalVisible && (
        <div className="requestModalContainer">
          <div className="requestModalWrapper">
            {requestsLoading ? (
              "Loading requests..."
            ) : requestsError ? (
              "Error loading requests."
            ) : requests.length > 0 ? (
              requests.map(request => (
                <div key={'req' + request.id} className="requestContainer">
                  <FriendItem friend={request} />
                  <div className="actions">
                    <button
                      className="accept-btn"
                      onClick={() => approveMutation.mutate(request.friendRequestId)}
                      aria-label={`Accept request from ${request.name}`}
                    >
                      Accept
                    </button>
                    <button
                      className="reject-btn"
                      onClick={() => rejectMutation.mutate(request.friendRequestId)}
                      aria-label={`Reject request from ${request.name}`}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              ))
            ) : (
              "No friend requests found"
            )}
            <button className="close" onClick={handleClose} aria-label="Close request modal">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FriendsListing;
