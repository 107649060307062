import React from "react";
import "./friendItemMessages.scss";

const FriendItemMessages = ({ friend, handleFriendClick, isActive }) => {
  // console.log(isActive, friend.userId);
  return (
    <div className={`friendItem ${isActive ? 'active' : ""}`}
      onClick={() => 
      handleFriendClick(friend)}
      >
      <div className="profilePic">
        <img src={friend.userProfileImagePath} alt="" />
      </div>
      <div className="info">
        <span>{friend.userFirstName + " " + friend.userLastName}</span>
        <p>some bio </p>
      </div>
    </div>
  );
};

export default FriendItemMessages;
