import React, { useEffect, useContext, useState } from 'react';
import Post from "../post/Post";
import "./posts.scss";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import LoadingPost from "../loading/LoadingPost";
import CircularProgress from '@mui/material/CircularProgress';

const Posts = ({ userId , isTimeline}) => {
  const { currentUser } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const postKey = isTimeline ? userId : currentUser.id 
    
  // Define fetch function for useInfiniteQuery
  const fetchPosts = async ({ pageParam = 0 }) => {
   
    const endpoint = isTimeline
      ? `/ssakti/users/user/userTimeline/${currentUser.UUID}/${userId == currentUser.UUID ? 'sameUser' :userId}`
      : `/ssakti/users/home/home/v1/${currentUser.id}`;
  
    try {
      const response = await makeRequest.get(endpoint, {
        params: { cursor: pageParam, size: 6 }, 
      });   
      if (response.status === 204) {        
        return {
          data: [],
          homePostData: [],
          postsData: [],
          hasNextPage: false,
          nextPageNo: undefined,
        };
      }            
      return response.data;

    } catch (error) {
      // console.error('Fetch error:', error); 
      
      throw error;
    }
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isError,
  } = useInfiniteQuery(
    ['posts', postKey],
    fetchPosts,
    {
      getNextPageParam: (lastPage) => {
        // console.log('Last page:', lastPage); 
        return lastPage.hasNextPage ? lastPage.nextCursor : undefined;
      },      
      refetchOnWindowFocus:false,
      staleTime: 600000, 
      cacheTime: 600000,
    }
  );  

  // Detect scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop
        < document.documentElement.offsetHeight - 100 || // Trigger a bit before the bottom
        isFetching
      ) return;

      if (hasNextPage) {
        fetchNextPage();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [fetchNextPage, hasNextPage, isFetching]);

  return (
    <div className="posts">
      {isError ? (
        <div>No Posts!</div>
      ) : isLoading  ? (
        // <LoadingPost />
        <div className='fetching'>
          <CircularProgress />
        </div>
      ) : data.pages.length === 0 || data?.length ===0  ? (
        <div>No Posts!</div>
      ) : (
        data.pages.flatMap(page => page?.homePostData || page?.postsData).map(post => (
          <Post post={post} key={post.postId} postKey={postKey} />
        ))
      )}
      {/* Optionally, you can show a loading indicator for fetching more posts */}
      
      {isFetching && !isLoading && <div className='fetching'><CircularProgress/></div>}
    </div>
  );
};

export default Posts;
