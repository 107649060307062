import { useEffect, useState } from "react";
import { makeRequest } from "../../axios";
import "./update.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AWS from 'aws-sdk';
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import { notification } from 'antd';
import imageCompression from 'browser-image-compression';
import { useParams } from "react-router-dom";

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

const Update = ({ setOpenUpdate, user }) => {
  const [cover, setCover] = useState(null);
  const [profile, setProfile] = useState(null);
  const [updatePassword, setUpdatePassword] = useState(false); // Toggle for updating password
  const [texts, setTexts] = useState({
    firstName: user.userFirstName,
    lastName: user.userLastName,
    dob: user.userBirthDate,
    address: user.userLocation,
    orgId : user.orgId,    
    password: '', // Password initially empty
  });

  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const params = useParams()
  const [org, setOrg] = useState([]);

  const S3_BUCKET = 'streesakti';

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion
  });

  const s3 = new AWS.S3();
  const fetchOrgnisation = async () => {
    try {
      const response = await makeRequest.get("/ssakti/users/user/getOrgDetails");
      setOrg(response.data.details);     

    } catch (err) {
      console.error(err);
    }
  };

  useEffect(()=>{
    fetchOrgnisation();
  },[])  

  const handleChange = (e) => {
    setTexts((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (user) => {
      return makeRequest.put(`ssakti/users/user/updateUser/${currentUser.id}`, user);
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(["user"]);
        queryClient.invalidateQueries(["lastestActivities"]);
        // setCurrentUser((prev) => {
        //   return { ...prev, profilePic: variables.userProfileImagePath };
        // });

        async function userUpdate(){
          console.log(params.id)
          const user = JSON.parse(localStorage.getItem('user'));
          try {
            const res = await makeRequest.get(`ssakti/users/user/userAbout/${params.id}`)

            if(res.data){
              setCurrentUser((prevUser) => {                               
                  return { ...prevUser, 
                    username: res.data.userFirstName,
                    profilePic: res.data.userProfileImage,
                    name: res.data.userFirstName ,
                    city: res.data.userLocation,
                  };
                });
            }

            if (user) {
                user.profilePic = res.data.userProfileImage ;
                user.username = res.data.userFirstName;
                user.name = res.data.userFirstName ;
                user.city = res.data.userLocation;

                localStorage.setItem('user', JSON.stringify(user));
                
              } else{
                console.log('No user data found in localstorage')
              }
            
          } catch (error) {
            console.error("Error While updating current User:", error);
          }

        }
        userUpdate()


        notification.success({
          message: 'Profile updated successfully',
        });
      },
    }
  );

  const handleClick = async (e) => {
    e.preventDefault();
    console.log(texts.orgId)
  
    // Validation for required fields
    if (!texts.firstName || !texts.lastName || !texts.dob || !texts.address || !texts.orgId) {
      notification.error({
        message: 'Error',
        description: 'First Name, Last Name, Date of Birth,Orgnisation and Address are required!',
      });
      return; // Exit if validation fails
    }
  
    let coverImageFile=null;
    let profileImageFile=null;
  
    // Handle Cover Image
    if (cover) {
      const imageOptions = {
        maxSizeMB: 0.10, // 150KB
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
  
      try {
        const compressedBlob = await imageCompression(cover, imageOptions);
        coverImageFile = new File([compressedBlob], cover.name, {
          type: compressedBlob.type,
          lastModified: cover.lastModified,
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }  
    // else if (user.userCoverProfileImage) {

    //   console.log("in else cover")
    //   try {
    //     const response = await fetch(user.userCoverProfileImagePath);
    //     const blob = await response.blob();
    //     coverImageFile = new File([blob], 'profile_image.jpg', { type: 'image/jpeg' });
    //   } catch (error) {
    //     console.error("Error fetching profile image:", error);
    //   }}
  
    if (profile) {
      const imageOptions = {
        maxSizeMB: 0.10, // 150KB
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
  
      try {
        const compressedBlob = await imageCompression(profile, imageOptions);
        profileImageFile = new File([compressedBlob], profile.name, {
          type: compressedBlob.type,
          lastModified: profile.lastModified,
        });
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }  
    // else if (user.userProfileImage) {
    //   console.log("in else profile")
    //   try {
    //     const response = await fetch(user.userProfileImagePath);
    //     const blob = await response.blob();
    //     profileImageFile = new File([blob], 'profile_image.jpg', { type: 'image/jpeg' });
    //   } catch (error) {
    //     console.error("Error fetching profile image:", error);
    //   } }
  
    // Prepare the payload
    const formData = new FormData();
    formData.append("userFirstName", texts.firstName);
    formData.append("userLastName", texts.lastName);
    formData.append("userDateOfBirth", texts.dob);
    formData.append("userAddress", texts.address);
    formData.append("orgId", texts.orgId);
    if(profileImageFile){
      formData.append("userProfileImagePath", profileImageFile);
    }
    if(coverImageFile){
       formData.append("userCoverProfileImagePath", coverImageFile);
    }
   
  
    // Add password to payload only if updating
    if (updatePassword) {
      formData.append("userPassword", texts.password);
    }
  
    // Send the data to the backend
    mutation.mutate(formData);
  
    setOpenUpdate(false);
    setCover(null);
    setProfile(null);
  };
  

  return (
    <div className="update">
      <div className="wrapper">
        <h1>Update Your Profile</h1>
        <form>
          <div className="files">
            <label htmlFor="cover">
              <span>Cover Picture</span>
              <div className="imgContainer">
                <img
                  src={
                    cover
                      ? URL.createObjectURL(cover)
                      : user.userCoverProfileImage
                  }
                  alt=""
                />
                <CloudUploadIcon className="icon" />
              </div>
            </label>
            <input
              type="file"
              id="cover"
              style={{ display: "none" }}
              onChange={(e) => setCover(e.target.files[0])}
            />
            <label htmlFor="profile">
              <span>Profile Picture</span>
              <div className="imgContainer">
                <img
                  src={
                    profile
                      ? URL.createObjectURL(profile)
                      : user.userProfileImage
                  }
                  alt=""
                />
                <CloudUploadIcon className="icon" />
              </div>
            </label>
            <input
              type="file"
              id="profile"
              style={{ display: "none" }}
              onChange={(e) => setProfile(e.target.files[0])}
            />
          </div>

          {/* First Name Input */}
          <div className="input-wrapper">
          <div>
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={texts.firstName}
              onChange={handleChange}
              placeholder="Enter first name"
              required
            />
          </div>

          {/* Last Name Input */}
          <div>
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={texts.lastName}
              onChange={handleChange}
              placeholder="Enter last name"
              required
            />
          </div>
          </div>

          {/* Date of Birth Input */}
          <div className="input-wrapper">
          <div>
            <label>Date of Birth</label>
            <input
              type="date"
              name="dob"
              value={texts.dob}
              onChange={handleChange}
              required
            />
          </div>

          {/* Address Input */}
          <div>
            <label>Address</label>
            <input
              type="text"
              name="address"
              value={texts.address}
              onChange={handleChange}
              required
            />
          </div>
          </div>
          {/* Organisation Name */}
          <div>
            <label>Organisation Name</label>
              <select 
                onChange={handleChange} 
                value={texts.orgId} 
                name="orgId">
                  <option>Select Organisation</option>
                  {
                      org && org.map((item, index) => (
                          <option key={index} value={item.orgId}>{item.orgName}</option>
                      ))
                  }
              </select>          
          </div>         
          {/* Password Toggle */}
          <div className="input-wrapper">
          <div>
            <label id="password-ag">
              <input
                type="checkbox"
                checked={updatePassword}
                onChange={() => setUpdatePassword(!updatePassword)}
              />
              Do you want to update your password?
            </label>
          </div>

          {/* Password Input (visible only if updatePassword is true) */}
          {updatePassword && (
            <div>
              <label>Password</label>
              <input
                type="password"
                value={texts.password}
                name="password"
                onChange={handleChange}
                placeholder="Enter new password"
              />
            </div>
          )}
          </div>

          <button onClick={handleClick}>Update</button>
        </form>
        <button className="close" onClick={() => setOpenUpdate(false)}>
          Close
        </button>
      </div>
    </div>
  );
}

export default Update;
