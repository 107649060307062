import "./rightBar.scss";
import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { makeRequest } from "../../axios";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import admin_img from "../../assets/admin_img_2.jpg";
// import admin_img_2 from "../../assers/login.jpg"
import LoadingActivities from "./LoadingActivities";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import { Modal, Carousel } from "antd";
import BoltIcon from "@mui/icons-material/Bolt";
import useRightBarVisibility from "../../hooks/useRightBarVisibility";
import { useTranslation } from "react-i18next";
const RightBar = () => {
  const location = useLocation();
  const isMessagesPage = location.pathname === "/messages";

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [ads, setAds] = useState([]);

  const { currentUser } = useContext(AuthContext);
  const isRighBarVisible = useRightBarVisibility();
  const {t} = useTranslation();

  const {
    isLoading,
    error,
    data: latestActivities,
  } = useQuery(["lastestActivities"], () => {
    return makeRequest.get("ssakti/users/home/lastActivity").then((res) => {
      return res.data.activityListData;
    });
  }, {
    refetchOnWindowFocus:false,
  });

  const district = currentUser.districtId;
  const apiUrl = process.env.REACT_APP_API_URL;

  const getAdsFromAdmin = async () => {
    try {      
      const response = await makeRequest.get(
        apiUrl + `/sskati/users/posts/post/${district}`
      );
      if (response.status === 200) {
        // console.log("this is 200", response);
        setAds(response.data.postData);
      } else {
        console.log("error getting ads");
        setAds([]);
        return;
      }
    } catch (error) {
      console.log(`from ${apiUrl}/sskati/users/posts/post/${district}`, error);
    }
  };

  useEffect(() => {
    getAdsFromAdmin();
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOK = () => {
    setIsModalVisible(false);
  };

  const contentStyleImg = {
    height: "250px",
    objectFit: "cover",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  const navigate = useNavigate();

  // console.log(latestActivities, isLoading, error, "lastActivity")
  return (
    <div className={`rightBar ${isMessagesPage ? "messagePage" : ""} ${isRighBarVisible ? "" : "hidden"}`}>
      <div className="container">
        <div className="item">
          <h3>{t("latestActivities")}</h3>
          <hr className="hr" />
          {isLoading ? (
            <LoadingActivities />
          ) : (
            latestActivities?.map((activity) => {
              return (
                <div className="user">
                  <div className="userInfo">
                    <img
                      src={activity.userProfileImagePath}
                      alt={activity.userFirstName}
                    />
                    <p>
                      <span>
                        {activity.userFirstName + " " + activity.userLastName}
                      </span>{" "}
                      {activity.activityType === "post"
                        ? t("addedNewPost")
                        : t("addedNewComment")}
                    </p>
                    <span className="time">{moment(activity.createdAt).fromNow()}</span>
                  </div>
                  
                </div>
              );
            })
          )}
        </div>
      </div>
      <div className="container ">
        <div className="item admin-ads">
          <div
            className="headingAds"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h3>{t("trendingPosts")}</h3>
            <BoltIcon className="star" />
          </div>
          {ads && ads.length > 0 ? (
            <Carousel
            className="ads"
              autoplay
              style={{
                backgroundColor: "white ",
                width: "380px",
                height: "80%",
                margin: "auto",
                overflow:"hidden"
              }}
            >
              {
                ads.map((ad,index)=>{
                  return (
                    <div key={index}>
                      <img src={ad.postImageUrl} alt={ad.postName} />
                      <div className="carFooter">
                      <h2>{ad.postName}</h2>
                      <button className="button" onClick={()=>navigate('/events')}>Know More</button>
                      </div>
                    </div>
                  )
                })
              }
            </Carousel>
          ) : (
            <div>No Posts</div>
          )}
        </div>
      </div>
      <Modal
        title={ads.postName}
        visible={isModalVisible}
        onOk={handleOK}
        onCancel={handleCancel}
        okButtonProps={{
          style: { backgroundColor: "#5271ff", color: "white" },
        }}
        footer={[
          <button
            style={{
              backgroundColor: "#d8ae7e",
              color: "black",
              border: "none",
              borderRadius: "5px",
              width: "4rem",
              height: "2rem",
              fontWeight: "600",
              fontSize: "0.9rem",
            }}
            key="logout"
            type="primary"
            onClick={handleOK}
          >
            OK
          </button>,
        ]}
      >
        <img
          src={ads.postImageUrl}
          width="100%"
          height="300px"
          style={{ borderRadius: "10px" }}
        />
        <p style={{ fontSize: "1rem", fontWeight: "bold" }}>
          {ads.advertisementDescription}
        </p>
      </Modal>
    </div>
  );
};

export default RightBar;
